
#services {
    background: url(../../Assets/darkbg.jpg) repeat;
    padding-bottom: 7rem;
}
.services h2 {
    margin-bottom: 5rem;
}

.services-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
}

.services-container .services-box {
    flex: 1 1 28rem;
    background: var(--second-bg-color);
    padding: 3rem 2rem 4rem;
    border-radius: 2rem;
    text-align: center;
    border: .2rem solid var(--bg-color);
    transition: .5s ease-in-out;
}

.services-container .services-box:hover {
    border-color: var(--main-bg-color);
    transform: scale(1.02);
}

.services-box i {
    font-size: 7rem;
    color: var(--main-color);
}

.services-box h3 {
    font-size: 2.6rem;
}

.services-box p {
    font-size: 1.6rem;
    margin: 1rem 0 3rem;
}
