
.contact h2 {
    margin-bottom: 3rem;
}

#contact {
    background: var(--second-bg-color);
}

.contact form {
    max-width: 70rem;
    margin: 1rem auto;
    text-align: center;
    margin-bottom: 3rem;
}

.contact form .input-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
    width: 100%;
    padding: 1.5rem;
    font-size: 1.6rem;
    color: var(--text-color);
    background: var(--bg-color);
    border-radius: .8rem;
    margin: .7rem 0;
}

.contact form .input-box input {
    width: 49%;
}

.contact form textarea {
    resize: none;
}

.contact form .btn {
    margin-top: 2rem;
    cursor: pointer;
}

.message {
    text-align: center;
    padding: 1rem 2rem;
    background: #ccfcff;
    max-width: 70rem;
    margin: 0 auto;
    font-size: 2rem;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    color: green;
    font-weight: 600;
}

.small-text {
    text-align: left;
    font-size: 1.2rem;
    font-style: italic;
    color: #ccc;
}