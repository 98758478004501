
.blog-container {
    display: flex;
    font-size: 1.6rem;
    gap: 5%;
}

.blog-container a {
    color: var(--link-color);
    font-style: italic;
}

#blog {
    margin-bottom: 3rem;
}

#blog h2{
    margin-bottom: 4rem;
}

#blog .topic h2 {
    margin-bottom: 1rem;
}

.sidenav {
    flex: 1 1 15%;
}

.rightnav {
    flex: 1 1 85%;
    overflow: hidden !important;
    position: relative;
}

.rightnav .description button {
    background: none;
    color: var(--link-color);
    font-size: 1.6rem;
    font-style: italic;
}

.sidenav ul li {
    list-style: none;
    margin-bottom: 1rem;
    font-style: italic;
    cursor: pointer;
    font-size: 2rem;
}

span.small {
    display: block;
    margin-bottom: 2rem;
    height: 0.3rem;
    width: 30%;
    background: var(--text-color);
    transition: all .5s ease-in-out;
    display: none;
}

.sidenav ul li:hover span.small {
    width: 80%;
    background: var(--main-color);
}

.sidenav ul li.blog-active{
    color: var(--main-color)
}

.sidenav ul li.blog-active span.small {
    background: var(--main-color);
    width: 80%;
}


.article {
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    position: relative;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
}

.article.blog-active {
    transform: translateX(0%);
    opacity: 1 !important;
    transition: all 0.5s ease-in-out;

}

.article:not(.blog-active) {
    opacity: 0;
    display: none;
    transition: all 0.5s ease-in-out;
  }

.article div {
    flex: 1 1 30%;
    margin-bottom: 3rem;
    padding: 1rem;
    /* box-shadow: 0 0 1rem var(--second-bg-color); */
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    cursor: pointer;
    padding-bottom: 2rem;
    border-bottom: 1px solid white;
}

.article span {
    display: block;
    color: var(--text-color);
    margin-bottom: .2rem;
}

.article img {
    width: 100%;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}


.article .date {
    font-style: italic;
    color: #2DCFEC;
}

.article .topic {
    color: var(--main-color-lighter);
}


.description {
    overflow: hidden;
    color: var(--text-color);
}


