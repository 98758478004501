
.home {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    gap: 2%;
    padding-bottom: 7rem;
    padding-top: 14rem;
}

.programming {
    width: 100%;
    height: 35vw;
    border: 4px solid var(--main-color);
    z-index: 5;
    background: var(--bg-color);
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.programming img {
    width: 80px;
    height: 80px;
}

.home-img {
    flex: 1 1 464px;
    height: 350px;
    position: relative;
    overflow: hidden;
    background: url(../../Assets/myskills_v4.png) no-repeat top center;
    background-size: 430px;
    cursor: grab;
    display: flex;
    align-items: center;
    font-size: 2rem;
    max-height: 300px;
}

.home-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
}

.home-img span {
    display: block;
    color: var(--text-color);
    font-style: italic;
}


.skills {
    position: absolute;
    background: transparent;
    width: 309px;
    height: 54px;
    right: 0;
}
.frontend, .backend, .content, .graphics, .projectmanage {
    transform: translateX(-107%);
    transition: transform .5s ease-in-out;
    position: absolute;
}

.skillsActive {
    transform: translateX(3%);
    transition: transform .5s ease-in-out;
}

.noSkillsActive {
    transform: translateX(-103%);
    transition: transform .5s ease-in-out;
}

.skills#frontend{
    top: 11px;
}

.skills#backend {
    top: 67px;
}
.skills#content {
    top: 124px;
}
.skills#graphics{
    top: 182px;
}
.skills#projectmanage {
    top: 240px;
}

.home-content {
    flex: 1 1 50%;
    z-index: 99;
}

.home-content h3 {
    font-size: 3.2rem;
    font-weight: 700;
}

.home-content h3:nth-of-type(2) {
    margin-bottom: 2rem;
}

span.multiple-text {
    color: var(--main-color);
}

.home-content h1 {
    font-size: 5.6rem;
    font-weight: 700;
    line-height: 1.3;
}

.home-content p {
    font-size: 1.6rem;
}

.home-content a {
    margin-top: 3rem;
}

.social-media a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: .2rem solid var(--main-color);
    border-radius: 50%;
    font-size: 2rem;
    color: var(--main-color);
    margin: 3rem 1.5rem 3rem 0rem;
    transition: .5s ease;
}

.social-media a:hover {
    background: var(--main-color);
    color: var(--secondary-color);
    border: 0 0 1rem var(--main-color);
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: #b61924; */
    background-image: url('');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
canvas{
    display:block;
    vertical-align: bottom;
  }
