.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 9%;
    background: var(--bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.header.sticky {
    border-bottom: .1rem solid rgba(0, 0, 0, .2);
}

.logo {

    color: var(--text-color);
    /* -webkit-text-stroke: 0.1px var(--main-color); */
    font-weight: 600;
    cursor: default;

    margin-left: 5px;
    margin-right: 5px;

}

.newbrand {
    position: absolute;
    width: 169px;
    height: 73px;
    background: var(--main-color);
    left: 9%;
    color: var(--text-color);
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.3rem;
    font-weight: 600;
    box-shadow: 0 0 1rem var(--main-color);
    z-index: 10;
}

.newbrand::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 73px;
    border-width: 16px 85px;
    border-style: solid;
    border-color: var(--main-color) transparent transparent;
    z-index: 10;
}

.navbar a {
    font-size: 1.7rem;
    color: var(--text-color);
    margin-right: 4rem;
    transition: .3s;
}

.navbar a:hover, .navbar a.active {
    color: var(--main-color);
}

#menu-icon {
    font-size: 3.6rem;
    color: var(--text-color);
    display: none;
}

@media all and (max-width: 768px) {
    #menu-icon {
        display: block !important;
    }
    .newbrand {
        height: 66px !important;
    }
    .newbrand::after {
        top: 64px !important;
    }
    .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 1rem 3%;
        background: var(--bg-color);
        border-top: .1rem solid rgba(0, 0, 0, .2);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
        display: none;
        transition: .5s ease;
    }
    .navbar.active {
        display: block !important;
    }
    .navbar a {
        display: block !important;
        font-size: 2rem;
        margin: 3rem 0;
    }
}