
.portfolio {
    background: var(--second-bg-color);
    background: url(../../Assets/lightbg.png) repeat;
}

#portfolio {
    padding-bottom: 10rem;
}

.tab-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    font-size: 2rem;
    gap: 2rem;
}

.tab-container h3.base {
    color: var(--main-color);
    text-decoration: underline;
    text-decoration-color: var(--text-color);
}

.portfolio h2 {
    margin-bottom: 4rem;
}

.portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2.5rem;
}

.portfolio-container .portfolio-box {
    position: relative;
    border-radius: 2rem;
    box-shadow: 0 0 1rem var(--bg-color);
    overflow: hidden;
    display: flex;
}

.portfolio-box img {
    width: 100%;
    transition: .5s ease;
}

.portfolio-box:hover img {
    transform: scale(1.1);
}

.portfolio-box .portfolio-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 4rem;
    transform: translateY(100%);
    transition: .5s ease;
    color: black;
}

.portfolio-box:hover .portfolio-layer {
    transform: translateY(0%);
}

.portfolio-layer h4 {
    font-size: 3rem;
}

.portfolio-layer p {
    font-size: 1.6rem;
    margin: .3rem 0 1rem;
}

.portfolio-layer a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: var(--text-color);
    border-radius: 50%;
}

.portfolio-layer a i {
    font-size: 2rem;
    color: var(--second-bg-color);
}
