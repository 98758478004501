@import url('https://fonts.googleapis.com/css?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

.redborder {
    border: 2px solid red;
}
main {
    position: relative;
}

.btn {
    display: inline-block;
    padding: 1rem 2.8rem;
    background: var(--main-color);
    border-radius: 1rem;
    box-shadow: 0 0 1rem var(--main-color);
    font-size: 1.6rem;
    color: var(--text-color);
    letter-spacing: .1rem;
    font-weight: 600;
    transition: .5s ease;
}

.btn:hover {
    box-shadow: none;
    cursor: pointer;
}

:root {
    --bg-color: #1f242d;
    --second-bg-color: #323946;
    --text-color: #fff;
    /* --main-color: #0ef; */
    /* --main-color: #E6FB04; */
    --main-color: #FF6600;
    --main-color-lighter: #F7751E;
    --link-color: #2DECB8;
    --modal-list: rgb(245, 222, 179);
    --modal-list-span-color: rgb(0, 191, 255);
    
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

span {
    color: var(--main-color);
}

body {
    background: var(--bg-color);
    color: var(--text-color);
}

section {
    padding: 10rem 9% 2rem;
}
.heading {
    text-align: center;
    font-size: 4.5rem;
}

.hide {
    display: none !important;
    transition: all 0.1s ease;
}


/* MODAL */

.modalGuy .date {
    font-style: italic;
    color: #2DCFEC;
}

.modalGuy .topic {
    color: var(--main-color-lighter);
}

.modalGuy .description {
    overflow: hidden;
    color: var(--text-color);
}
.modalGuy .date {
    margin-top: 2rem;
    display: block;
}

.modalGuy .description {
    display: block;
    margin-top: 2rem;
}
/* modal */
.modalGuy {
    background: var(--second-bg-color);
    height: 100%;
    width: 60%;
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 3rem 3rem 3rem 3rem;

}
.modalGuyOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.modalGuy i {
    font-size: 4rem;
    position: absolute;
    right: 0;
    margin: 1rem;
    color: var(--main-color);
    cursor: pointer;
}

.modalGuy a {
    color: var(--link-color);
}
.modalGuy img {
    display: block;
    margin-top: 4rem;
    border-radius: 22% 0%;
    width: 320px;
    height: auto;
}
.modalGuy .itslist {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-style: italic;
    margin-left: 3rem;
}

.modalGuy .itslist ul li {
    list-style: circle !important;
}

.modalGuy .itslist li {
    padding: 0rem 0rem 0.5rem 0.5rem;
    border-bottom: 1px dashed var(--text-color);
    color: var(--modal-list);
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.modalGuy .itslist li span {
    display: block;
    color: var(--modal-list-span-color);
    font-weight: 600;
}
.modalGuy .itscode {
    margin: 2rem 2rem;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    background: var(--bg-color);
    padding: 2rem;
    color: var(--text-color) !important;
    font-weight: 100 !important;
    font-style: normal;
}
#blogSender {
    display: none;
}



/* BREAKPOINTS */

@media all and (max-width: 1200px) {
    html {
        font-size: 55%;
    }
    .logo::before {
        width: 149px;
    }
    .logo::after {
        border-left: 73px solid transparent;
        border-right: 73px solid transparent;
    }
    .modalGuy {
        width: 80%;
    }
}

@media all and (max-width: 991px) {
    section {
        padding: 10rem 3% 2rem !important;
    }
    .home {
        flex-direction: column;
    }
    .home-img {
        width: 49rem;
        height: auto;
        left: 0;
        text-align: left;
        background-size: 430px;
    }
    .skills#frontend{
        top: 12px;
    }

    .skills#backend {
        top: 67px;
    }
    .skills#content {
        top: 123px;
    }
    .skills#graphics{
        top: 181px;
    }
    .skills#projectmanage {
        top: 241px;
    }
    .frontend, .backend, .content, .graphics, .projectmanage {
        top: 80px;
    }
    .home-content {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }
    .newbrand {
        left: 3% !important;
    }
    .header, .footer {
        padding: 2rem 3% !important;
    }
    .about-img {
        background-size: 300px;
    }
    .portfolio, .services {
        padding-bottom: 7rem;
    }
    .contact {
        min-height: auto;
    }
    .navbar a {
        margin-right: 2rem;
    }
}

@media all and (max-width: 900px) {
    .article div {
        flex: 1 1 40% !important;
    }   
}

@media all and (max-width: 768px) {
  
    .about {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .about-img {
        background-size: 300px;
        width: 300px;
        height: 300px;
        margin-right: 0;
        align-self: flex-start;
        max-height: 331px;
        background: url(./Assets/lap.png) center bottom / 300px no-repeat;
    }

    .portfolio-container {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    #blogSender {
        display: block !important;
        position: absolute;
        bottom: -3px;
        right: 0;
        color: var(--text-color);
        font-style: italic;
    }
    #blogSender i {
        font-size: 3rem;
        color: var(--main-color);
        vertical-align: middle;
    }

}

@media all and (max-width: 600px) {
    .home {
        flex-direction: column;
    }
    .home-content h3 {
        font-size: 2.6rem;
    }
    .home-content h1 {
        font-size: 5rem;
    }
    .home-img img {
        width: 70vw;
        margin-top: 4rem;
    }
    .about {
        flex-direction: column-reverse;
    }
    .about img {
        width: 70vw;
        margin-top: 4rem;
    }
    .services h2 {
        margin-bottom: 3rem;
    }
    .portfolio h2 {
        margin-bottom: 3rem;
    }
    .portfolio-container {
        grid-template-columns: 1fr !important;
    }
    .blog-container {
        flex-direction: column;
        justify-content: center;
    }
    .rightnav, .sidenav {
        flex: unset;
    }
    .rightnav {
        margin-bottom: 3rem;
    }

    .sidenav {
        display: inline-block;
        max-width: 310px;
        text-align: center;
        margin: 0 auto;
    }
    .sidenav ul li {
        margin-right: 1rem;
        display: inline-block;
    }
    .contact form .input-box input {
        width: 100%;
    }
    .modalGuy {
        width: 90%;
    }
}

@media all and (max-width:450px) {
    html {
        font-size: 50%;
    }
    .home-img {
        width: 39rem;
        background-size: 273px !important;
        max-height: 233px;
    }
    .frontend, .backend, .content, .graphics, .projectmanage {
        font-size: 1.5rem;
        top: 44px !important;
    }
    .skills {
        width: 230px !important;
        height: 30px !important;
    }
    .skills#frontend{
        top: 10px !important;
    }

    .skills#backend {
        top: 44px !important;
    }
    .skills#content {
        top: 79px !important;
    }
    .skills#graphics{
        top: 119px !important;
    }
    .skills#projectmanage {
        top: 154px !important;
    }
    .article div {
        flex: 1 1 100% !important;
    }
    .footer {
        flex-direction: column-reverse;
    }
    .footer p {
        text-align: center;
        margin-top: 2rem;
    }
}

@media all and (max-width:320px) {
    .home-img img, .about-img img {
        width: 90vw;
    }
}
