.about {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    background: var(--second-bg-color);
    background: url(../../Assets/lightbg.png) repeat;
    border-top: 5px solid var(--main-color);
    border-bottom: 5px solid var(--main-color);
    padding-bottom: 7rem;
}

.about-img {
    height: 650px;
    margin-right: 2rem;
    flex: 1 1 500px;
    background: url(../../Assets/lap.png) bottom / 316px no-repeat;
    max-height: 348px;
}

.about-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
}

.about-content {
    flex: 1 1 60%;
}

.about-img img {
    width: 35vw;

}

.about-content h2 {
    text-align: left;
    line-height: 1.2;
}

.about-content h3 {
    font-size: 2.6rem;
}

.about-content p {
    font-size: 1.6rem;
    margin: 2rem 0 3rem;
}
