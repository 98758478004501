
.footer {
    display: flex;
    justify-content: space-between;
    background: var(--bg-color);
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 9%;
}

.footer-text p {
    font-size: 1.6rem;
}

.footer-text a {
    color: #fff;
    font-size: 10px;
}

.footer-iconTop a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    border: 2px solid var(--main-color);
    border-radius: 0.8rem;
    transition: .5s ease;
}

.footer-iconTop a:hover {
    box-shadow: 0 0 1rem var(--main-color);
}

.footer-iconTop a i {
    font-size: 2.4rem;
    color: var(--main-color);
}
